import React from 'react';

import './App.css';
import NavBar from './components/nav/NavBar';

function App() {
  return (
    <div>
      <NavBar />
      <main>
        <h1>Coming soon</h1>
      </main>
    </div>
  );
}

export default App;
