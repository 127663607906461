import styled from 'styled-components';
import { ReactComponent as SavingsLogo } from '../../assets/svg/savings.svg';

const StyledNavBar = styled.nav`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  background-color: #2176FF;
  padding: 0.625rem;
`;

const StyledSavingsLogo = styled(SavingsLogo)`
  width: 2.5rem;
  height: 2.5rem;
  path {
    fill: #fff;
  }
`;

const SiteName = styled.strong`
  font-size: 2rem;
  font-weight: 700;
  margin: 0;
  padding: 0;
  color: #fff;
`;

export default function NavBar() {
  return (
    <StyledNavBar>
      <StyledSavingsLogo />
      <SiteName>Yields.fyi</SiteName>
    </StyledNavBar>
  );
}
